import React, { useEffect, useState } from "react"
import Layout from "../components/shared-layout";
import { Alert, Box, IconButton, Skeleton, TextField, Typography } from "@mui/material";
import { observer } from "mobx-react";
import { useTranslation } from "react-i18next";
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
// @ts-ignore
import SnowMountain from "../images/snow-mountain.jpg";
import { ITEM_PERFECT_INLINED, PADDING_HORIZONTAL } from "../constants/style";
import ListAltIcon from '@mui/icons-material/ListAlt';
import useStores from "../hooks/use-stores";
import { navigate } from "gatsby";
import { Language } from "../constants/options";
import ProductCard from "../components/product-card";
import { isWeb } from "../utilities/platform";
import { GetLanguage, IsAuthed } from "../utilities/general";

const WishlistPage = observer(({ location }: { location: any }) => {
  const { t }: any = useTranslation();
  const { userStore } = useStores();

  useEffect(() => {
    if (IsAuthed()) userStore.getWishList(GetLanguage());
    else navigate("/");
  }, []);

  return (
    <Layout pageName={t('WISHLIST')}
      breadCrumb={[
        { to: location.state ? location.state.prevPath : "/", label: t('HOME') },
        { to: "", label: t('WISHLIST') }
      ]}>
      {userStore.loading ? <>
        <Skeleton variant="rectangular" width="100%" height={40} className="mt-6 mb-10" />
        <Skeleton variant="rectangular" width="100%" height={100} className="mb-4" />
        <Skeleton variant="rectangular" width="100%" height={100} className="mb-4" />
        <Skeleton variant="rectangular" width="100%" height={100} className="mb-4" />
      </> :
        <Box className={PADDING_HORIZONTAL}>
          <Box sx={ITEM_PERFECT_INLINED} className="shadow-lg p-4 mb-4 bg-gray-50">
            <ListAltIcon />
            <Box ml={1}>{userStore.wishlist.length} {t('RESULTS_FOUND')}</Box>
          </Box>
          {userStore.wishlist.length == 0 ? <Box>{t('NO_PRODUCTS_FOUND')}</Box>
            : userStore.wishlist.map((wishProduct: any, i: number) => (
              <ProductCard
                key={`wishlist_product_${i}`}
                mode={wishProduct.groupProducts ? "supplier" : "product"}
                product={wishProduct}
                onClick={() => navigate(`/product-detail?productId=${wishProduct.tid}`, { state: { prevPath: isWeb ? location.pathname : '' } })}
              />))}
        </Box>}
    </Layout>
  )
});

export default WishlistPage;
